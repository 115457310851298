import { useDispatch } from "react-redux";
import { ITab } from "../../interfaces";
import { AllStats } from "../../interfaces/report";
import LoadingOverlay from "../atoms/LoadingOverlay";
import ReportTabPrint from "./ReportTabPrint";
import { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { AppDispatch } from "../../configureStore";
import { SET_IS_PRINT } from "../../redux/reducer/tabs";

interface PrintPdfParams {
    tabs: ITab[];
    institutionName: string;
    dateRange: { startDate: string; endDate: string; };
    stats: AllStats;
}

const PrintPdf = ({
    tabs,
    institutionName,
    dateRange,
    stats,
}: PrintPdfParams) => {
    const dispatch = useDispatch<AppDispatch>();

    const componentRef = useRef<any>();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        onAfterPrint: () => {
            dispatch({ type: SET_IS_PRINT, isPrint: false });
        },
        documentTitle: `${institutionName}-${new Date(dateRange.startDate).toLocaleDateString().replace(/\//g, '-')}-${new Date(dateRange.endDate).toLocaleDateString().replace(/\//g, '-')}`
    });

    setTimeout(() => {
        handlePrint()
    }, 1000)

    return (
        <div>
            <LoadingOverlay />
            <button onClick={handlePrint}>Print this out!</button>
            <div ref={componentRef} id="slide-deck" style={{ width: 'fit-content', backgroundColor: 'green' }}>
                {tabs.map((tab, index) => (
                    <ReportTabPrint
                        key={`printpage-${index}`}
                        index={index}
                        stats={stats}
                        type={tab.type}
                        startDate={dateRange.startDate}
                        endDate={dateRange.endDate}
                        tabs={tabs}
                        active={institutionName}
                    />
                ))}
            </div>
        </div>
    );
};

export default PrintPdf;
