import { Box } from "@mui/material";
import globalStyles from "../globalStyles";
import { Outlet } from "react-router-dom";
import AppHeader from "./organisms/AppHeader";
import { useState } from "react";

const Layout = ({ noAuth = false }: { noAuth?: boolean }) => {
    const [open] = useState(false);

    return (
        <Box
            sx={{
                minHeight: "100vh",
                color: "#000133",
            }}
        >
            {!noAuth && (
                <AppHeader
                    open={open}
                />
            )}
            <Box
                component="main"
                sx={[
                    globalStyles.pt8,
                    globalStyles.px4,
                ]}
            >
                <Outlet />
            </Box>
        </Box>
    );
};

export default Layout;
