import { Box, Typography } from "@mui/material";
import { useMemo } from "react";
import { HW } from "../../../interfaces";
import { DateTime } from "luxon";
import globalStyles from "../../../globalStyles";

interface TitleParams {
    current?: any;
    startDate: string;
    endDate: string;
    active?: string;
}

const Title = ({
    current,
    startDate,
    endDate,
    active = '',
}: TitleParams) => {
    const hw = useMemo<HW>(() => {
        if (current) {
            return {
                width: current.offsetWidth,
                height: current.offsetHeight,
            }
        }

        return {
            width: document.body.clientWidth * 0.55,
            height: document.body.clientHeight * 0.55,
        }
    }, [current]);

    return (
        <div style={{ height: '100%', display: 'flex', flexDirection: 'row' }}>
            <div style={{ width: '1%', height: '100%', backgroundColor: '#1f4e93' }} />
            <div style={{ width: '27%', height: '100%', backgroundImage: `url('/TitleShot.png')`, backgroundSize: 'cover' }} />
            <Box sx={{ alignSelf: 'center', justifySelf: 'center', marginLeft: '12px' }}>
                <Typography
                    variant="h3"
                    fontFamily="Playfair Display"
                    color="#454545"
                    fontSize={(active?.length || 0) > 20 ? 40 : hw.width / 16}
                    sx={globalStyles.mb2}
                >
                    {active ?? 'Institution'}
                </Typography>
                <Typography
                    variant="h5"
                    fontFamily="Roboto"
                    fontSize={16}
                    fontWeight={600}
                    color="#454545"
                    textTransform="capitalize"
                    sx={[{ textTransform: 'capitalize' }, globalStyles.mb1]}
                >
                    PRODUCT USAGE STATISTICS
                </Typography>
                <Typography
                    variant="h5"
                    color="#454545"
                    fontFamily="Roboto"
                    fontSize={16}
                >
                    {DateTime.fromISO(startDate).toLocaleString(DateTime.DATE_FULL)} - {DateTime.fromISO(endDate).toLocaleString(DateTime.DATE_FULL)}
                </Typography>
            </Box>
        </div>
    );
};

export default Title;
