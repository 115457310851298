import { Box, Typography } from "@mui/material";
import { ReportTabType } from "../../interfaces";
import globalStyles from "../../globalStyles";

const SmallBar = ({ type }: { type: ReportTabType }) => (
    <Box
        style={{
            height: '20%',
        }}
        sx={[globalStyles.flexCenterStart, globalStyles.pl2]}
        className="small-left-border"
    >
        <Typography
            variant="h6"
            fontFamily="Playfair Display"
            fontWeight={500}
            fontSize={30}
        >
            {
                type === 'Highlights'
                    ? 'Usage Highlights'
                    : type === 'Comparison'
                        ? 'Month-Wise Comparison'
                        : type === 'Completion'
                            ? 'Completion Time Distribution'
                            : type === 'Action'
                                ? 'Lender Resubmission Request Reasons'
                                : type === 'Orders'
                                    ? 'Order Types and Top Processors'
                                    : type
            }
        </Typography>
    </Box>
);

export default SmallBar;
