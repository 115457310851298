import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { ReportTabType } from "../../interfaces";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../configureStore";
import { CHANGE_TAB_TITLE, CHANGE_TAB_TYPE } from "../../redux/reducer/tabs";

interface TabSelectParams {
    index: number;
    type: ReportTabType;
}

const TabSelect = ({
    index,
    type,
}: TabSelectParams) => {
    const dispatch = useDispatch<AppDispatch>();

    return (
        <FormControl sx={{ m: 1, width: 300 }}>
            <InputLabel id={`slide-type-select-${index}`}>Slide Type</InputLabel>
            <Select
                labelId={`slide-type-select-${index}`}
                label="Slide Type"
                size="small"
                sx={{ minWidth: 180 }}
                value={type}
                onChange={(e) => {
                    dispatch({ type: CHANGE_TAB_TYPE, value: e.target.value, index });
                    dispatch({ type: CHANGE_TAB_TITLE, value: e.target.value, index });
                }}
            >
                <MenuItem value="Unassigned">Unassigned</MenuItem>
                <MenuItem value="Title">Title</MenuItem>
                <MenuItem value="Highlights">Highlights</MenuItem>
                <MenuItem value="Comparison">Comparison</MenuItem>
                <MenuItem value="Completion">Completion</MenuItem>
                <MenuItem value="Action">Action</MenuItem>
                <MenuItem value="Orders">Orders</MenuItem>
            </Select>
        </FormControl>
    );
};

export default TabSelect;
