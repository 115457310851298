import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";
import { AppDispatch, RootState } from "../../configureStore";
import { getSaved } from "../../redux/reducer/client";
import { AllStats, SupportStats } from "../../interfaces/report";
import { ITab } from "../../interfaces";
import ReportTabClient from "../organisms/ReportTabClient";
import { Box, Tab, Tabs, Typography } from "@mui/material";
import globalStyles from "../../globalStyles";
import Icon from "../atoms/Icon";
import { DateTime } from "luxon";
import downloadExcel from "../../utils/excel";
import Button from "../atoms/Button";

const SavedReport = () => {
    const institution = useParams();
    const searchParams = useSearchParams();
    const dispatch = useDispatch<AppDispatch>();
    const [value, setValue] = useState<number>(0);

    const dateRange = useSelector<RootState, { startDate: string; endDate: string; }>(({ client }) => client.dateRange)
    const stats = useSelector<RootState, AllStats>(({ client }) => client.stats);
    const supportStats = useSelector<RootState, SupportStats>(({ client }) => client.supportStats);
    const useName = useSelector<RootState, string>(({ client }) => client.useName);
    const tabs = useSelector<RootState, ITab[]>(({ client }) => client.tabs);

    useEffect(() => {
        const summary = searchParams?.[0]?.get('summary');
        dispatch(getSaved({
            summary,
            institutionName: String(institution.name),
        }))
    }, []);

    return (
        <>
            <Box sx={[globalStyles.rowReverse, globalStyles.mb2]}>
                <Button
                    color="primary"
                    variant="contained"
                    name="Download Support Stats"
                    onClick={() => {
                        downloadExcel(supportStats, useName, { startDate: DateTime.fromISO(dateRange.startDate).toFormat('yyyy-LLL-dd'), endDate: DateTime.fromISO(dateRange.endDate).toFormat('yyyy-LLL-dd') });
                    }}
                    endIcon={(<Icon name="Download" />)}
                >
                    Support Stats
                </Button>
            </Box>
            <Tabs
                sx={{ display: 'none' }}
                value={value}
                onChange={(_, v) => { setValue(v); }}
            >
                {tabs.map((props) => (<Tab label={(
                    <Box sx={globalStyles.flexCenterCenter}>
                        <Typography variant="h6">{props.title}</Typography>
                    </Box>
                )} />))}
            </Tabs>
            <Box sx={globalStyles.flexCenterCenter}>
                <Icon
                    iconButton={{
                        id: 'go-left',
                        style: {
                            position: 'absolute',
                            left: 16,
                            top: '50%',
                        }
                    }}
                    fontSize="large"
                    onClick={() => { value !== 0 && setValue(value - 1); }}
                    name="ArrowBackIos"
                />
                <Box>
                    {tabs.map((tab, index) => (
                        <ReportTabClient
                            key={`printpage-${index}`}
                            index={index}
                            stats={stats}
                            type={tab.type}
                            startDate={dateRange.startDate}
                            endDate={dateRange.endDate}
                            tabs={tabs}
                            active={useName}
                            value={value}
                        />
                    ))}
                </Box>
                <Icon
                    iconButton={{
                        id: 'go-right',
                        style: {
                            position: 'absolute',
                            right: 16,
                            top: '50%',
                        }
                    }}
                    fontSize="large"
                    onClick={() => { value !== tabs.length - 1 && setValue(value + 1); }}
                    name="ArrowForwardIos"
                />
            </Box>
        </>
    );
};

export default SavedReport;
