import { Box } from "@mui/material";
import SmallBar from "../../atoms/SmallBar";
import globalStyles from "../../../globalStyles";
import TopProcessors from "./TopProcessors";
import OrderType from "./OrderType";
import { AllStats } from "../../../interfaces/report";

const Orders = ({ stats }: { stats: AllStats }) => {
    return (
        <div style={{ height: '100%', flexDirection: 'column', display: 'flex' }}>
            <SmallBar type="Orders" />
            <Box sx={globalStyles.cardBox}>
                <OrderType data={stats.orderType} />
                <TopProcessors allRequests={stats.highlights.Borrowers} data={stats.processors} />
            </Box>
        </div>
    );
};

export default Orders;
