import { Box } from "@mui/material";
import SmallBar from "../../atoms/SmallBar";
import globalStyles from "../../../globalStyles";
import OrderType from "../../molecules/OrderType";
import TopProcessors from "../../molecules/TopProcessors";

const Orders = () => {
    return (
        <div style={{ height: '100%', flexDirection: 'column', display: 'flex' }}>
            <SmallBar type="Orders" />
            <Box sx={globalStyles.cardBox}>
                <OrderType />
                <TopProcessors />
            </Box>
        </div>
    );
};

export default Orders;
