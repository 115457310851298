import { AppDispatch } from "../../configureStore";
import { AllStats, SupportStats } from "../../interfaces/report";
import execute from "../http/execute";
import failure from "../http/failure";
import { get } from "../http/request";
import success from "../http/success";
import { SET_DATE, SET_INSTITUTION } from "./institution";

const initialState: {
    stats: AllStats;
    supportStats: SupportStats;
} = {
    stats: {
        allRequests: 0,
        highlights: {
            None: 0,
            "Loans": 0,
            Borrowers: 0,
            Transcripts: 0,
            "Loan Processors": 0,
            "Avg Processing Time": 0,
            "Success Rate": 0,
            "Transcripts Returned": 0,
            "Transcripts in Day or Less": 0,
            "Action Needed Rate": 0,
            "ID.me Account Rate": 0,
            "IRS Rejection Rate": 0,
        },
        monthly: [],
        completion: {
            period: 'day',
            data: [],
        },
        actionReasons: [],
        orderType: {
            no: 0,
            yes: 0,
        },
        processors: [],
    },
    supportStats: {
        breakdown: [],
        byIssue: {},
        byEmployee: {},
    }
}

export const GET_STATS_REQUEST = 'stats/GET_STATS_REQUEST';
export const GET_STATS_SUCCESS = 'stats/GET_STATS_SUCCESS';
export const GET_STATS_FAILURE = 'stats/GET_STATS_FAILURE';

export const GET_SUPPORT_STATS_REQUEST = 'stats/GET_SUPPORT_STATS_REQUEST';
export const GET_SUPPORT_STATS_SUCCESS = 'stats/GET_SUPPORT_STATS_SUCCESS';
export const GET_SUPPORT_STATS_FAILURE = 'stats/GET_SUPPORT_STATS_FAILURE';

type ActionParams =
    | { type: typeof SET_INSTITUTION; }
    | { type: typeof SET_DATE; }
    | { type: typeof GET_STATS_SUCCESS; data: AllStats; }
    | { type: typeof GET_SUPPORT_STATS_SUCCESS; data: SupportStats; };

function reducer(state = initialState, action: ActionParams): typeof initialState {
    switch (action.type) {
        case SET_INSTITUTION:
        case SET_DATE:
            return {
                ...state,
                stats: initialState.stats,
            }
        case GET_SUPPORT_STATS_SUCCESS:
            return {
                ...state,
                supportStats: action.data,
            }
        case GET_STATS_SUCCESS:
            return {
                ...state,
                stats: action.data,
            }
        default:
            return state;
    }
}

export const getStats = (payload: { FICode?: string; startDate?: string; endDate?: string; monthWise: string | number; includeCurrent?: boolean }) => (dispatch: AppDispatch) => {
    execute(get, '/stats', payload)
        .then(success(dispatch, GET_STATS_SUCCESS, payload))
        .catch(failure(dispatch, GET_STATS_FAILURE, payload));

    dispatch({
        type: GET_STATS_REQUEST,
        payload,
    });
};

export const getSupportStats = (payload: { FICode?: string; startDate?: string; endDate?: string; monthWise: string | number; includeCurrent?: boolean }) => (dispatch: AppDispatch) => {
    execute(get, '/stats/support', payload)
        .then(success(dispatch, GET_SUPPORT_STATS_SUCCESS, payload))
        .catch(failure(dispatch, GET_SUPPORT_STATS_FAILURE, payload));

    dispatch({
        type: GET_SUPPORT_STATS_REQUEST,
        payload,
    });
};

export default reducer;
