import { DateTime } from "luxon";
import { AppDispatch } from "../../configureStore";
import { Institution } from "../../interfaces/institution";
import execute from "../http/execute";
import failure from "../http/failure";
import { get, post } from "../http/request";
import success from "../http/success";
import { ITab } from "../../interfaces";
import { AllStats, SupportStats } from "../../interfaces/report";

export const GET_INSTITUTIONS_REQUEST = 'institution/GET_INSTITUTIONS_REQUEST';
export const GET_INSTITUTIONS_SUCCESS = 'institution/GET_INSTITUTIONS_SUCCESS';
export const GET_INSTITUTIONS_FAILURE = 'institution/GET_INSTITUTIONS_FAILURE';

export const SET_INSTITUTION = 'institution/SET_INSTITUTION';
export const SET_DATE = 'institution/SET_DATE';

export const SET_MONTH_WISE_MONTHS = 'institution/SET_MONTH_WISE_MONTHS';
export const SET_MONTH_WISE_RANGE = 'institution/SET_MONTH_WISE_RANGE';
export const SET_MONTH_WISE_CURRENT = 'institution/SET_MONTH_WISE_CURRENT';

export const CREATE_SHARE_LINK_REQUEST = 'institution/CREATE_SHARE_LINK_REQUEST';
export const CREATE_SHARE_LINK_SUCCESS = 'institution/CREATE_SHARE_LINK_SUCCESS';
export const CREATE_SHARE_LINK_FAILURE = 'institution/CREATE_SHARE_LINK_FAILURE';

export const SET_USE_NAME = 'institution/SET_USE_NAME';

const initialState: {
    institutions: { [FICode: string]: Institution };
    allInstitutions: string[];
    activeInstitution?: string;
    useName?: string;
    dateRange: {
        startDate: string;
        endDate: string;
    };
    monthWise: {
        dateRange: boolean;
        includeCurrent: boolean;
        months: number;
    };
    shareLink?: string;
} = {
    institutions: {},
    allInstitutions: [],
    dateRange: {
        startDate: DateTime.now().startOf('month').toISO(),
        endDate: DateTime.now().toISO(),
    },
    monthWise: {
        dateRange: false,
        includeCurrent: false,
        months: 3,
    },
}

type ActionParams =
    | { type: typeof GET_INSTITUTIONS_SUCCESS; data: Institution[]; }
    | { type: typeof SET_INSTITUTION; id: string; }
    | { type: typeof SET_DATE; startDate: string; endDate: string; }
    | { type: typeof SET_MONTH_WISE_RANGE; useDateRange: boolean; }
    | { type: typeof SET_MONTH_WISE_CURRENT; includeCurrent: boolean; }
    | { type: typeof SET_MONTH_WISE_MONTHS; months: number; }
    | { type: typeof SET_USE_NAME; name: string; }
    | { type: typeof CREATE_SHARE_LINK_SUCCESS; data: string; };

function reducer(state = initialState, action: ActionParams): typeof initialState {
    switch (action.type) {
        case CREATE_SHARE_LINK_SUCCESS: {
            return {
                ...state,
                shareLink: action.data,
            }
        }

        case SET_USE_NAME: {
            return {
                ...state,
                useName: action.name,
            }
        }

        case SET_MONTH_WISE_MONTHS: {
            return {
                ...state,
                monthWise: {
                    months: action.months,
                    includeCurrent: state.monthWise.includeCurrent,
                    dateRange: state.monthWise.dateRange,
                }
            }
        }

        case SET_MONTH_WISE_CURRENT: {
            return {
                ...state,
                monthWise: {
                    months: state.monthWise.months,
                    includeCurrent: action.includeCurrent,
                    dateRange: state.monthWise.dateRange,
                }
            }
        }

        case SET_MONTH_WISE_RANGE: {
            return {
                ...state,
                monthWise: {
                    months: state.monthWise.months,
                    includeCurrent: state.monthWise.includeCurrent,
                    dateRange: action.useDateRange,
                }
            }
        }

        case GET_INSTITUTIONS_SUCCESS: {
            return {
                ...state,
                allInstitutions: action.data.map(({ FICode }) => FICode),
                institutions: action.data.reduce((acc, curr) => ({ ...acc, [curr.FICode]: curr }), state.institutions)
            }
        }

        case SET_INSTITUTION: {
            return {
                ...state,
                activeInstitution: action.id,
            }
        }

        case SET_DATE: {
            return {
                ...state,
                dateRange: {
                    startDate: action.startDate,
                    endDate: action.endDate,
                }
            }
        }

        default: {
            return state;
        }
    }
}

export const setIncludeCurrentMonth = (includeCurrent: boolean) => (dispatch: AppDispatch) => {
    dispatch({ type: SET_MONTH_WISE_CURRENT, includeCurrent })
}

export const setMonthWiseRange = (useDateRange: boolean) => (dispatch: AppDispatch) => {
    dispatch({ type: SET_MONTH_WISE_RANGE, useDateRange })
}

export const setMonthWiseMonth = (months: number) => (dispatch: AppDispatch) => {
    dispatch({ type: SET_MONTH_WISE_MONTHS, months, })
}

export const getInstitutions = (payload: { name?: string; }) => (dispatch: AppDispatch) => {
    execute(get, '/institutions', payload)
        .then(success(dispatch, GET_INSTITUTIONS_SUCCESS, payload))
        .catch(failure(dispatch, GET_INSTITUTIONS_FAILURE, payload));

    dispatch({
        type: GET_INSTITUTIONS_REQUEST,
        payload,
    });
};

export const saveSettings = (payload: { fiCode: string; institutionName: string; tabs: ITab[]; stats: AllStats; supportStats: SupportStats; dateRange: { startDate: string; endDate: string; }; useName?: string; }) => (dispatch: AppDispatch) => {
    execute(post, '/save', payload)
        .then(success(dispatch, CREATE_SHARE_LINK_SUCCESS, payload))
        .catch(failure(dispatch, CREATE_SHARE_LINK_FAILURE, payload));

    dispatch({
        type: CREATE_SHARE_LINK_REQUEST,
        payload,
    });
};

export const setInstitution = (id: string) => (dispatch: AppDispatch) => {
    dispatch({
        type: SET_INSTITUTION,
        id,
    })
}

export const setDate = (startDate: string | null, endDate: string | null) => (dispatch: AppDispatch) => {
    dispatch({
        type: SET_DATE,
        startDate,
        endDate,
    })
}

export default reducer;
