import { combineReducers } from "redux";
import auth from "./auth";
import client from "./client";
import institution from "./institution";
import stats from "./stats";
import status from "./status";
import tabs from "./tabs";

export interface Payload<T = any> {
    onSuccess?: (data: any) => void;
    onError?: (data: any) => void;
    headers?: T;
    input?: T;
    data?: T;
    noLoad?: boolean;
}

const rootReducer = combineReducers({
    auth,
    client,
    institution,
    stats,
    status,
    tabs,
});

export default rootReducer;
