import { Box } from "@mui/material";
import globalStyles from "../../../globalStyles";
import TabSelect from "../../molecules/TabSelect";
import { ReportTabType } from "../../../interfaces";
import Highlights from "./Highlights";
import Comparison from "./Comparison";
import Title from "./Title";

interface MainParams {
    index: number;
    type: ReportTabType;
}

const Main = ({
    index,
    type,
}: MainParams) => (
    <Box sx={globalStyles.mb1}>
        <TabSelect
            index={index}
            type={type}
        />
        {
            type === 'Title'
                ? <Title />
                : type === 'Highlights'
                    ? <Highlights index={index} />
                    : type === 'Comparison'
                        ? <Comparison index={index} />
                        : <div />
        }
    </Box>
);

export default Main;
