import { Payload } from ".";

export interface Me {
    email: string;
    name: string;
    level: 0 | 1 | 2;
    imageUrl?: string;
    role: 'Admin' | 'User';
    employeeId: string;
    institutionId: string;
    fiCode?: string;
}

const initialState: {
    me?: Me;
    institution?: any;
    institutionId?: string;
    noAccess: boolean;
    userNotFound: boolean;
} = {
    noAccess: false,
    userNotFound: false,
};

export const GET_ME_REQUEST = 'auth/GET_ME_REQUEST';
export const GET_ME_SUCCESS = 'auth/GET_ME_SUCCESS';
export const GET_ME_FAILURE = 'auth/GET_ME_FAILURE';

type ActionParams =
    | { type: typeof GET_ME_SUCCESS; payload: Payload; data: Me };

function reducer(state = initialState, action: ActionParams): typeof initialState {
    switch (action.type) {
        case GET_ME_SUCCESS:
            if (action.data) {
                return {
                    ...state,
                    me: {
                        email: action.data.email,
                        employeeId: action.data.employeeId,
                        level: action.data.level,
                        name: action.data.name,
                        role: action.data.role,
                        institutionId: action.data.institutionId,
                        fiCode: 'action.data.institution?.fiCode',
                    },
                };
            }
            return state;

        default:
            return state;
    }
}

export default reducer;
