import { PieChart } from "@mui/x-charts/PieChart";
import { graphColors } from "../../utils/colors";
import { Box } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "../../configureStore";
import { OrderType as IOrderType } from "../../interfaces/report";

const OrderType = () => {
    const data = useSelector<RootState, IOrderType>(({ stats: { stats: { orderType: ot } } }) => ot);

    return (
        <Box sx={{ width: '50%', borderRight: '1px #454545 solid', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <PieChart
                series={[{
                    arcLabel: (params) => `${Math.round((params.value / ((data.no + data.yes) || 1)) * 100).toString()}%`,
                    data: [
                        {
                            id: 0,
                            value: data.no,
                            label: 'Signed Authorization',
                            color: graphColors[0],
                        },
                        {
                            id: 1,
                            value: data.yes,
                            label: 'IRS Account + Signed Authorization',
                            color: graphColors[1],
                        },
                    ]
                }]}
                width={300}
                height={300}
                slotProps={{
                    legend: {
                        direction: 'row',
                        position: {
                            vertical: 'bottom',
                            horizontal: 'middle',
                        },
                        labelStyle: {
                            fontSize: 12,
                        },
                        itemMarkWidth: 12,
                        itemMarkHeight: 4,
                        padding: 2,
                    }
                }}
            />
        </Box>
    );
};

export default OrderType;
