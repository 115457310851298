import { Box, Checkbox, FormControlLabel } from "@mui/material";
import globalStyles from "../../../globalStyles";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../configureStore";
import { ComparisonConfig } from "../../../interfaces";
import { changeComparisonValue } from "../../../redux/reducer/tabs";

interface ComparisonParams {
    index: number;
}

const Comparison = ({
    index,
}: ComparisonParams) => {
    const dispatch = useDispatch<AppDispatch>();
    const config = useSelector<RootState, ComparisonConfig | undefined>(({ tabs }) => tabs.tabs[index].config as ComparisonConfig)

    return (
        <Box sx={globalStyles.flexCenterAround}>
            <FormControlLabel
                control={(
                    <Checkbox
                        name="Loan Professionals"
                        checked={config?.details?.['Loan Professionals'] || false}
                        onChange={(_, checked) => {
                            dispatch(changeComparisonValue({ name: 'Loan Professionals', newValue: checked, tabIndex: index }))
                        }}
                    />
                )}
                sx={[globalStyles.border, globalStyles.pr2, globalStyles.brSm]}
                label="Loan Professionals"
            />
            <FormControlLabel
                control={(
                    <Checkbox
                        name="Loans"
                        checked={config?.details?.['Loans'] || false}
                        onChange={(_, checked) => {
                            dispatch(changeComparisonValue({ name: 'Loans', newValue: checked, tabIndex: index }))
                        }}
                    />
                )}
                sx={[globalStyles.border, globalStyles.pr2, globalStyles.brSm]}
                label="Loans"
            />
            <FormControlLabel
                control={(
                    <Checkbox
                        name="Borrowers"
                        checked={config?.details?.['Borrowers'] || false}
                        onChange={(_, checked) => {
                            dispatch(changeComparisonValue({ name: 'Borrowers', newValue: checked, tabIndex: index }))
                        }}
                    />
                )}
                sx={[globalStyles.border, globalStyles.pr2, globalStyles.brSm]}
                label="Borrowers"
            />
            <FormControlLabel
                control={(
                    <Checkbox
                        name="Transcripts"
                        checked={config?.details?.['Transcripts'] || false}
                        onChange={(_, checked) => {
                            dispatch(changeComparisonValue({ name: 'Transcripts', newValue: checked, tabIndex: index }))
                        }}
                    />
                )}
                sx={[globalStyles.border, globalStyles.pr2, globalStyles.brSm]}
                label="Transcripts"
            />
        </Box>
    );
};

export default Comparison;
