import { Box, Typography } from "@mui/material";
import globalStyles from "../../globalStyles";
import { UsageBoxParams } from "../../interfaces/report";

const UsageBox = ({
    name = 'Title',
    value = 0,
    isPercent = false,
}: UsageBoxParams) => (
    <Box sx={globalStyles.usageBox}>
        <Typography fontWeight={600} textAlign='center' fontSize={name.length > 20 ? 14 : undefined}>{name.toUpperCase()}</Typography>
        <Box sx={globalStyles.usageCircle}>
            <Typography fontSize={20} fontWeight={500}>{Math.round(value)}{isPercent ? '%' : ''}</Typography>
        </Box>
    </Box>
);

export default UsageBox;
