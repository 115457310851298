import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './redux/reducer';
import { createStateSyncMiddleware, initMessageListener } from 'redux-state-sync';

const store = configureStore({
    reducer: rootReducer,
    // @ts-expect-error middleware is messed up for now
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(createStateSyncMiddleware({
        })),
});

initMessageListener(store);

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch;

export default store;
