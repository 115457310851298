import { Box, Grid } from "@mui/material";
import SmallBar from "../../atoms/SmallBar";
import UsageBox from "../../molecules/UsageBox";
import globalStyles from "../../../globalStyles";
import { HighlightConfig, ITab } from "../../../interfaces";
import { useMemo } from "react";
import { AllStats, UsageBoxParams } from "../../../interfaces/report";

interface HighlightsParams {
    tabs: ITab[];
    stats: AllStats;
    index: number;
}

const Highlights = ({
    tabs,
    stats,
    index,
}: HighlightsParams) => {
    const titles = tabs[index]?.config as HighlightConfig;
    const highlights = stats.highlights;

    const arr = useMemo<UsageBoxParams[]>(() => (
        titles
            ? titles.details.filter((deet) => deet !== 'None').map<UsageBoxParams>((typeName) => ({
                isPercent: typeName.endsWith(' Rate'),
                name: typeName,
                value: highlights[typeName],
            }))
            : []
    ), [
        titles,
        highlights
    ]);

    return (
        <div style={{ height: '100%', flexDirection: 'column', display: 'flex', justifyContent: 'space-between' }}>
            <SmallBar type="Highlights" />
            <Box sx={[{ height: '80%' }, globalStyles.mx4, globalStyles.mt2]}>
                <Grid sx={{ height: '94%' }} justifyContent="space-around" container spacing={1}>
                    {arr.map(({ isPercent, name, value }) => (
                        <Grid item xs={4}><UsageBox isPercent={isPercent} name={name} value={value} /></Grid>
                    ))}
                </Grid>
            </Box>
        </div>
    );
};

export default Highlights;
