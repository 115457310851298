import XLSX from 'xlsx-js-style';
import { SupportStats } from '../interfaces/report';

const headerStyle = { alignment: { vertical: 'center', horizontal: 'center' }, fill: { patternType: 'solid', fgColor: { rgb: "d9d9d9" } }, font: { bold: true, name: "Segoe UI", sz: 9 } };
const dataStyle = { alignment: { vertical: 'center', horizontal: 'center' }, font: { name: "Segoe UI", sz: 9 } };

const titles: (string | null)[] = [
    'Ticket ID',
    'Status',
    'Subject',
    'Order Date',
    'Loan Professional',
    'Issue Description',
    null,
    'Issue Description',
    'Count',
    null,
    'Loan Professional',
    'Count',
];

const downloadExcel = (supportStats: SupportStats, institutionName?: string, dateRange?: { startDate: string; endDate: string; }) => {
    const wb = XLSX.utils.book_new();

    const titleRow = titles.map((title) => (title ? { v: title, t: "s", s: headerStyle } : { s: dataStyle }));
    const breakdownRows: any[] = supportStats.breakdown.map((item) => ([
        { v: item.id, t: "s", s: dataStyle },
        { v: item.status, t: "s", s: dataStyle },
        { v: item.subject, t: "s", s: dataStyle },
        { v: item.orderDate, t: "s", s: dataStyle },
        { v: item.loanProfessional, t: "s", s: dataStyle },
        { v: item.issueDescription, t: "s", s: dataStyle },
    ])
    )
    Object.keys(supportStats.byIssue).filter((v) => Boolean(v && supportStats.byIssue[v])).sort((a, b) => (supportStats.byIssue[b] - supportStats.byIssue[a])).forEach((item, idx) => {
        breakdownRows[idx].push(...[{ s: dataStyle }, { v: item, t: "s", s: dataStyle }, { v: supportStats.byIssue[item], t: "s", s: dataStyle }])
    });
    Object.keys(supportStats.byEmployee).filter((v) => Boolean(v && supportStats.byEmployee[v])).sort((a, b) => (supportStats.byEmployee[b] - supportStats.byEmployee[a])).forEach((item, idx) => {
        if (idx >= Object.keys(supportStats.byIssue).length) breakdownRows[idx].push(...[{ s: dataStyle }, { s: dataStyle }, { s: dataStyle }])
        breakdownRows[idx].push(...[{ s: dataStyle }, { v: item, t: "s", s: dataStyle }, { v: supportStats.byEmployee[item], t: "s", s: dataStyle }])
    });

    const ws1 = XLSX.utils.aoa_to_sheet([titleRow, ...breakdownRows]);

    ws1['!cols'] = [
        { width: 10 },
        { width: 10 },
        { width: 45 },
        { width: 15 },
        { width: 30 },
        { width: 25 },
        { width: 5 },
        { width: 30 },
        { width: 10 },
        { width: 5 },
        { width: 30 },
        { width: 10 },
    ];

    XLSX.utils.book_append_sheet(wb, ws1, institutionName);

    XLSX.writeFile(wb, `${institutionName || ''}-${dateRange?.startDate || ''}-${dateRange?.endDate || ''}.xlsx`);
}

export default downloadExcel;
