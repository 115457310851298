import { Box, Tab, Tabs, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import ReportTab from "../organisms/ReportTab";
import Icon from "../atoms/Icon";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../configureStore";
import { Institution } from "../../interfaces/institution";
import globalStyles from "../../globalStyles";
import { ITab } from "../../interfaces";
import { ADD_TAB, CHANGE_TAB_TITLE, REMOVE_TAB, SET_IS_PRINT } from "../../redux/reducer/tabs";
import PrintPdf from "../organisms/PrintPdf";
import { AllStats, SupportStats } from "../../interfaces/report";
import downloadExcel from "../../utils/excel";
import { DateTime } from "luxon";
import { saveSettings } from "../../redux/reducer/institution";

const Report = () => {
    const dispatch = useDispatch<AppDispatch>();
    const [editVisible, setEditVisible] = useState<boolean>(true);

    const supportStats = useSelector<RootState, SupportStats>(({ stats: { supportStats: ss } }) => ss);
    const tabs = useSelector<RootState, ITab[]>(({ tabs: { tabs: t } }) => t)
    const isPrint = useSelector<RootState, boolean>(({ tabs: { isPrint: t } }) => t)
    const active = useSelector<RootState, Institution | undefined>(({ institution }) => institution.institutions[institution.activeInstitution || ''])
    const fiCode = useSelector<RootState, string>(({ institution }) => institution.activeInstitution || '');
    const inst = useSelector<RootState, string | undefined>(({ institution: { institutions, activeInstitution } }) => institutions?.[activeInstitution || '']?.Name || '');
    const un = useSelector<RootState, string | undefined>(({ institution: { useName } }) => useName);
    const dateRange = useSelector<RootState, { startDate: string; endDate: string; }>(({ institution: { dateRange: dr } }) => dr);
    const stats = useSelector<RootState, AllStats>(({ stats: { stats: st } }) => st);
    const shareLink = useSelector<RootState, string | undefined>(({ institution }) => institution.shareLink);

    const [value, setValue] = useState<number>(0);

    useEffect(() => { if (value >= tabs.length) { setValue(tabs.length - 1); } }, [value, tabs.length]);
    useEffect(() => {
        if (active) {
            dispatch({ type: CHANGE_TAB_TITLE, value: active.Name, index: 0 });
        };
    }, [active, dispatch]);

    return (
        <Box>
            <Box sx={[globalStyles.rowReverse, globalStyles.mt2]}>
                <Icon
                    color="disabled"
                    name={editVisible ? "VisibilityOff" : "Visibility"}
                    onClick={() => { setEditVisible(!editVisible) }}
                />
                <Icon
                    color="disabled"
                    name={"Print"}
                    onClick={() => {
                        dispatch({ type: SET_IS_PRINT, isPrint: !isPrint });
                    }}
                />
                <Icon
                    color="disabled"
                    name="BorderAll"
                    onClick={() => {
                        downloadExcel(supportStats, inst, { startDate: DateTime.fromISO(dateRange.startDate).toFormat('yyyy-LLL-dd'), endDate: DateTime.fromISO(dateRange.endDate).toFormat('yyyy-LLL-dd') });
                    }}
                />
                <Icon
                    color="disabled"
                    name="Share"
                    onClick={() => {
                        dispatch(saveSettings({ fiCode, tabs, institutionName: active?.Name || '', stats, supportStats, dateRange, useName: un }));
                    }}
                />
                {
                    shareLink && <Typography>{shareLink}</Typography>
                }
            </Box>
            {isPrint ? (
                <PrintPdf
                    dateRange={dateRange}
                    institutionName={un || inst || 'institution'}
                    tabs={tabs}
                    stats={stats}
                />
            ) : (
                <>
                    <Tabs
                        sx={{ display: !editVisible ? 'none' : undefined }}
                        value={value}
                        onChange={(_, v) => { setValue(v); }}
                    >
                        {tabs.map((props, index) => (<Tab label={(
                            <Box sx={globalStyles.flexCenterCenter}>
                                <Typography variant="h6">{props.title}</Typography>
                                {Boolean(index) && <Icon
                                    name="Close"
                                    onClick={() => {
                                        dispatch({ type: REMOVE_TAB, index })
                                    }}
                                />}
                            </Box>
                        )} />))}
                        <Icon name="Add" fontSize="small" onClick={() => { dispatch({ type: ADD_TAB }); setValue(tabs.length) }} />
                    </Tabs>
                    {!editVisible && <Box sx={globalStyles.my8} />}
                    <Icon
                        iconButton={{
                            id: 'go-left',
                            style: {
                                position: 'absolute',
                                left: 16,
                                top: '50%',
                            }
                        }}
                        fontSize="large"
                        onClick={() => { value !== 0 && setValue(value - 1); }}
                        name="ArrowBackIos"
                    />
                    {
                        tabs.map((tab, index) => (
                            <ReportTab
                                value={value}
                                index={index}
                                type={tab.type}
                                visible={editVisible}
                            />
                        ))
                    }
                    <Icon
                        iconButton={{
                            id: 'go-right',
                            style: {
                                position: 'absolute',
                                right: 16,
                                top: '50%',
                            }
                        }}
                        fontSize="large"
                        onClick={() => { value !== tabs.length - 1 && setValue(value + 1); }}
                        name="ArrowForwardIos"
                    />
                </>
            )}

        </Box >
    );
};

export default Report;
