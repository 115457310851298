import './App.css';
import { PublicClientApplication } from '@azure/msal-browser';
import { jwtInterceptor } from './jwtInterceptor';
import { msalConfig } from './authConfig';
import WebApp from './components/WebApp';
import { MsalProvider } from '@azure/msal-react';
import { Provider } from 'react-redux';
import store from './configureStore';
import { useState } from 'react';
import LoadingOverlay from './components/atoms/LoadingOverlay';

function App() {
  const msalInstance = new PublicClientApplication(msalConfig);
  const [loaded, setLoaded] = useState<boolean>(false);
  msalInstance.initialize().then(() => { setLoaded(true); }).catch();
  jwtInterceptor(msalInstance);

  return loaded ? (
    <MsalProvider instance={msalInstance}>
      <Provider store={store}>
        <WebApp />
      </Provider>
    </MsalProvider>
  ) : <LoadingOverlay />
}

export default App;
