import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../configureStore";
import { HighlightConfig } from "../../../interfaces";
import { Grid, MenuItem, Select } from "@mui/material";
import { CHANGE_HIGHLIGHT_TYPE } from "../../../redux/reducer/tabs";

interface HighlightsParams {
    index: number;
}

const Highlights = ({
    index,
}: HighlightsParams) => {
    const dispatch = useDispatch<AppDispatch>();
    const config = useSelector<RootState, HighlightConfig | undefined>(({ tabs }) => tabs.tabs[index].config as HighlightConfig)

    return (
        <Grid container spacing={1}>
            {config?.details?.map((val, idx) => (
                <Grid
                    item
                    key={`${index}-highlight-${idx}`}
                    xs={2}
                >
                    <Select
                        size="small"
                        fullWidth
                        value={val}
                        onChange={(e) => {
                            dispatch({
                                type: CHANGE_HIGHLIGHT_TYPE,
                                newType: e.target.value,
                                index: idx,
                                tabIndex: index,
                            });
                        }}
                    >
                        <MenuItem value="None">None</MenuItem>
                        <MenuItem value="Loans">Loans</MenuItem>
                        <MenuItem value="Borrowers">Borrowers</MenuItem>
                        <MenuItem value="Transcripts">Transcripts</MenuItem>
                        <MenuItem value="Loan Processors">Loan Processors</MenuItem>
                        <MenuItem value="Avg Processing Time">Avg Processing Time</MenuItem>
                        <MenuItem value="Transcripts Returned">Transcripts Returned</MenuItem>
                        <MenuItem value="Action Needed Rate">Action Needed Rate</MenuItem>
                        <MenuItem value="IRS Rejection Rate">IRS Rejection Rate</MenuItem>
                        <MenuItem value="Transcripts in Day or Less">Transcripts in Day or Less</MenuItem>
                        <MenuItem value="ID.me Account Rate">ID.me Account Rate</MenuItem>
                        <MenuItem value="Success Rate">Success Rate</MenuItem>
                    </Select>
                </Grid>
            ))}
        </Grid>
    );
};

export default Highlights;
