import { BarChart } from "@mui/x-charts";
import SmallBar from "../../atoms/SmallBar";
import { useMemo } from "react";
import { graphColors } from "../../../utils/colors";
import { useSelector } from "react-redux";
import { RootState } from "../../../configureStore";
import { CompletionStats } from "../../../interfaces/report";

const Completion = () => {
    const completionData = useSelector<RootState, CompletionStats>(({ stats: { stats: { completion } } }) => completion);
    const sum = useMemo(() => completionData.data.reduce((a, b) => (a + b.amount), 0), [completionData]);
    const axisData = useMemo(() => completionData.data.map((a) => (a.timespan)), [completionData]);
    const allyData = useMemo(() => completionData.data.map((a) => (a.amount)), [completionData]);

    return (
        <div style={{ height: '100%', flexDirection: 'column', display: 'flex', justifyContent: 'space-between' }}>
            <SmallBar type="Completion" />
            <BarChart
                xAxis={[{ label: 'Time in Business Days', scaleType: 'band', data: axisData }]}
                yAxis={[{ label: 'Number of Transcripts' }]}
                series={[{ data: allyData, color: graphColors[0] }]}
                barLabel={({ value }) => {
                    const per = ((Number(value) / sum) * 100)
                    return `${per.toFixed(1)}%`;
                }}
                slotProps={{
                    barLabel: {
                        style: { fill: 'white' }
                    }
                }}
            />
        </div>
    );
};

export default Completion;
