import { Box, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from "@mui/material";
import globalStyles from "../../../globalStyles";
import { StringTotal } from "../../../interfaces/report";

const TopProcessors = ({
    allRequests,
    data,
}: {
    allRequests: number;
    data: StringTotal[];
}) => (
    <Box sx={{ width: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        {
            (data.length && (allRequests > 0)) ? (
                <TableContainer component={Box}>
                    <Box sx={[globalStyles.flexCenterCenter]}>
                        <Typography variant="h6">
                            Top {data.length} Loan Processors
                        </Typography>
                    </Box>
                    <Table>
                        <TableBody>
                            {data.map(({ name, total }) => (
                                <TableRow
                                    sx={{ '&:last-child td, &:last-child th': { borderBottom: 0 } }}
                                >
                                    <TableCell sx={[{ backgroundColor: '#EAEEF4', border: '1px white solid' }]} align="center">{name}</TableCell>
                                    <TableCell sx={[{ backgroundColor: '#EAEEF4', border: '1px white solid' }]} align="center">{total}</TableCell>
                                    <TableCell sx={[{ backgroundColor: '#EAEEF4', border: '1px white solid' }]} align="center">{((total / allRequests) * 100).toFixed(1)}%</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            ) : (
                <Typography>No orders placed</Typography>
            )
        }
    </Box>
);

export default TopProcessors;
