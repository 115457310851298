import { useMemo } from "react";
import { Box, Toolbar } from "@mui/material";
import { Buffer } from 'buffer';

import globalStyles from "../../globalStyles";
import { AppBar } from "../molecules/AppBar";
import IconMenu from "../molecules/IconMenu";
import Profile from "./Profile";
import Icon from "../atoms/Icon";
import { useLocation, useNavigate } from "react-router-dom";

interface AppHeaderParams {
    open: boolean
}

const AppHeader = ({
    open,
}: AppHeaderParams) => {
    const navigate = useNavigate();
    const location = useLocation();

    const user = useMemo(() => {
        try {
            const token = localStorage.getItem('ID_TOKEN');
            const encoded = token?.split('.')[1];
            return JSON.parse(Buffer.from(encoded || '', 'base64').toString('utf-8'));
        } catch {
            return { name: ' ' };
        }
    }, [])

    return (
        <AppBar
            position="fixed"
            open={open}
            sx={globalStyles.white}
        >
            <Toolbar sx={globalStyles.growBetween}>
                <Box sx={globalStyles.flexCenter}>
                    <Box
                        component="img"
                        sx={{
                            height: 40,
                            width: 40,
                        }}
                        alt="Halcyon Still Water"
                        src="/logo.png"
                    />
                </Box>

                <Box sx={globalStyles.flexCenter}>
                    <Icon
                        name="Settings"
                        color="action"
                        onClick={() => { navigate(location.pathname === '/institutions' ? '/' : '/institutions') }}
                        iconButton={{ sx: globalStyles.mr2 }}
                    />
                    <IconMenu
                        name="AccountCircleOutlined"
                        item={(<Profile user={user} />)}
                    />
                </Box>
            </Toolbar>
        </AppBar>
    );
};

export default AppHeader;
