import { useDispatch, useSelector } from "react-redux";
import { useMemo, useState } from "react";
import { DatePicker } from "@mui/x-date-pickers";
import {
    Box,
    Button as MuiButton,
    Checkbox,
    FormControlLabel,
    Grid,
    Paper,
    TextField,
    Typography,
} from "@mui/material";
import { DateTime } from 'luxon';

import { AppDispatch, RootState } from "../../configureStore";
import { setDate, setIncludeCurrentMonth, setInstitution, setMonthWiseMonth, setMonthWiseRange } from "../../redux/reducer/institution";
import globalStyles from "../../globalStyles";
import { Institution } from "../../interfaces/institution";
import { getStats, getSupportStats } from "../../redux/reducer/stats";
import Button from "../atoms/Button";

const InstitutionSelect = () => {
    const dispatch = useDispatch<AppDispatch>();
    const institutions = useSelector<RootState, { [FICode: string]: Institution }>(({ institution }) => institution.institutions);
    const allInstitutions = useSelector<RootState, string[]>(({ institution }) => institution.allInstitutions);
    const dateRange = useSelector<RootState, { startDate: string; endDate: string; }>(({ institution }) => institution.dateRange);
    const FICode = useSelector<RootState, string | undefined>(({ institution }) => institution.activeInstitution);
    const activeName = useSelector<RootState, string | undefined>(({ institution: { institutions: i, activeInstitution: ai } }) => i?.[ai || '']?.Name);
    const months = useSelector<RootState, number>(({ institution: { monthWise: { months: m } } }) => m);
    const useRange = useSelector<RootState, boolean>(({ institution: { monthWise: { dateRange: m } } }) => m);
    const includeCurrent = useSelector<RootState, boolean>(({ institution: { monthWise: { includeCurrent: m } } }) => m);
    const loading = useSelector<RootState, boolean>(({ status }) => status.loading['stats/GET_STATS']);

    const [search, setSearch] = useState<string | undefined>();
    const [startDate, setStartDate] = useState<DateTime>(DateTime.fromISO(dateRange.startDate));
    const [endDate, setEndDate] = useState<DateTime>(DateTime.fromISO(dateRange.endDate));

    const foundInstitutions = useMemo(() => (
        search
            ? allInstitutions.filter((inst) => (institutions[inst].Name || '').toLowerCase().includes(search.toLowerCase()))
            : allInstitutions
    ), [allInstitutions, search])

    return (
        <Grid container spacing={2} sx={globalStyles.mt2}>
            <Grid item xs={6}>
                <Paper sx={[globalStyles.flexStart, globalStyles.col, globalStyles.pa2]}>
                    <Typography variant="h6" width="100%" sx={[globalStyles.mb2, { textAlign: 'center' }]}>Institution{activeName ? ` - ${activeName}` : ''}</Typography>
                    <TextField
                        sx={{ width: '100%' }}
                        value={search}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => { setSearch(e.target.value); }}
                        variant="outlined"
                        rows={1}
                        size="small"
                        placeholder="Search"
                    />
                    {
                        foundInstitutions.map((inst) => (<MuiButton key={`name-${inst}`} sx={[{ textAlign: 'left', width: '100%' }, globalStyles.mt1]} variant="outlined" onClick={() => { dispatch(setInstitution(inst)); }}>{institutions[inst].Name}</MuiButton>))
                    }
                </Paper>
            </Grid>
            <Grid item xs={6}>
                <Paper sx={[globalStyles.col, globalStyles.pa2]}>
                    <Box sx={[globalStyles.col, globalStyles.flexCenter]}>
                        <Grid container alignItems="center" spacing={1}>
                            <Grid item xs={4}><Typography variant="h6" width="100%" sx={[globalStyles.mb2, { textAlign: 'center' }]}>Date Range</Typography></Grid>
                            <Grid item xs={4}>
                                <DatePicker
                                    sx={globalStyles.my1}
                                    label="Start"
                                    value={startDate}
                                    onChange={(v) => {
                                        if (v) {
                                            setStartDate(v);
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <DatePicker
                                    sx={globalStyles.my1}
                                    label="End"
                                    value={endDate}
                                    onChange={(v) => {
                                        if (v) {
                                            setEndDate(v);
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant="h6" width="100%" sx={[globalStyles.mb2, { textAlign: 'center' }]}>Month-Wise</Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <FormControlLabel
                                    control={(
                                        <Checkbox
                                            name="Use Date Range"
                                            checked={useRange}
                                            onChange={(_, checked) => {
                                                dispatch(setMonthWiseRange(checked));
                                            }}
                                        />
                                    )}
                                    sx={[globalStyles.border, globalStyles.pr2, globalStyles.brSm]}
                                    label="Use Date Range"
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <Box>
                                    <TextField
                                        label="Last Month Range"
                                        value={months}
                                        variant="outlined"
                                        type="text"
                                        maxRows={1}
                                        inputMode="numeric"
                                        size="small"
                                        disabled={useRange}
                                        onChange={(e) => {
                                            dispatch(setMonthWiseMonth(Number(e.target.value)))
                                        }}
                                    />
                                    <FormControlLabel
                                        control={(
                                            <Checkbox
                                                disabled={useRange}
                                                name="Include Current Month"
                                                checked={includeCurrent}
                                                size="small"
                                                onChange={(_, checked) => {
                                                    dispatch(setIncludeCurrentMonth(checked));
                                                }}
                                            />
                                        )}
                                        sx={[globalStyles.border, globalStyles.pr2, globalStyles.brSm, globalStyles.mt1]}
                                        label="Include Current Month"
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                    <MuiButton
                        sx={{ alignSelf: 'flex-end', marginTop: 2 }}
                        onClick={() => { dispatch(setDate(startDate.toISO(), endDate.toISO())); }}
                        variant="contained"
                    >
                        Set
                    </MuiButton>
                </Paper>
                <Box sx={[globalStyles.col, globalStyles.pa2]}>
                    <Button
                        sx={{ alignSelf: 'flex-end' }}
                        disabled={!FICode}
                        loading={loading}
                        onClick={() => {
                            dispatch(getStats({
                                FICode,
                                endDate: dateRange.endDate,
                                startDate: dateRange.startDate,
                                monthWise: useRange ? 'useRange' : months,
                                includeCurrent,
                            }));
                            dispatch(getSupportStats({
                                FICode,
                                endDate: dateRange.endDate,
                                startDate: dateRange.startDate,
                                monthWise: useRange ? 'useRange' : months,
                                includeCurrent,
                            }));
                        }}
                        variant="contained"
                    >
                        Fetch Data
                    </Button>
                </Box>
            </Grid>
        </Grid >
    );
};

export default InstitutionSelect;
