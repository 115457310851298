import { InteractionType } from "@azure/msal-browser";
import { HotKeys } from "react-hotkeys";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsalAuthentication } from "@azure/msal-react";
import { useDispatch, useSelector } from "react-redux";
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { LocalizationProvider } from "@mui/x-date-pickers";

import { AppDispatch, RootState } from "../configureStore";
import { useEffect, useState } from "react";
import { Alert, GlobalStyles, Snackbar } from "@mui/material";
import { clearError, clearSuccessful } from "../redux/reducer/status";
import InstitutionSelect from "./templates/InstitutionSelect";
import Layout from "./Layout";
import Report from "./templates/Report";
import { getInstitutions } from "../redux/reducer/institution";
import { authRoutes } from "../utils";
import SavedReport from "./templates/SavedReport";

const Pages = () => {
    const dispatch = useDispatch<AppDispatch>();
    useEffect(() => {
        dispatch(getInstitutions({}))
    }, [dispatch]);

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route index element={<Report />} />
                    <Route path="/institutions" element={<InstitutionSelect />} />
                </Route>
            </Routes>
        </BrowserRouter>
    );
};

const NoAuthPages = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/:name" element={<Layout noAuth />}>
                    <Route index element={<SavedReport />} />
                </Route>
            </Routes>
        </BrowserRouter>
    )
}

const WebApp = () => {
    const dispatch = useDispatch<AppDispatch>();
    const request = {
        scopes: [`api://${process.env.REACT_APP_TOKEN_AUDIENCE}/api`, 'User.Read'],
    };
    const { result } = !authRoutes.includes(window.location.pathname) ? { result: null } : useMsalAuthentication(InteractionType.Redirect, request);
    const error = useSelector<RootState, string | undefined>(({ status }) => status.error);
    const successful = useSelector<RootState, string | undefined>(({ status }) => status.successful);
    const [open, setOpen] = useState<boolean>(false);

    const keyMap = {
        LEFT: ["left"],
        RIGHT: ["right"],
    };

    const handlers = {
        LEFT: () => {
            document.getElementById("go-left")?.click();
        },
        RIGHT: () => {
            document.getElementById("go-right")?.click();
        },
    }

    useEffect(() => {
        if (error || successful) setOpen(true);
    }, [error, successful]);

    useEffect(() => {
        if (result) {
            const { accessToken, idToken } = result;
            localStorage.setItem('ACCESS_TOKEN', accessToken);
            localStorage.setItem('ID_TOKEN', idToken);
            try {
                localStorage.setItem('ACCESS_EXPIRY', (JSON.parse(Buffer.from(accessToken.split('.')[1], 'base64').toString('ascii')).exp * 1000).toString());
            } catch (err) {
                console.error(err);
            }
        }
    }, [dispatch, result]);

    return (
        <HotKeys keyMap={keyMap} handlers={handlers}>
            <LocalizationProvider dateAdapter={AdapterLuxon}>
                <GlobalStyles
                    styles={{
                        p: { color: '#454545' },
                        h1: { color: '#454545' },
                        h2: { color: '#454545' },
                        h3: { color: '#454545' },
                        h4: { color: '#454545' },
                        h5: { color: '#454545' },
                        h6: { color: '#454545' },
                    }}
                />

                <Snackbar
                    open={open}
                    autoHideDuration={5000}
                    onClose={(() => { dispatch(clearError()); dispatch(clearSuccessful()); setOpen(false); })}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                >
                    <Alert severity={successful ? "success" : "error"} color={successful ? "success" : "error"}>
                        {successful || error}
                    </Alert>
                </Snackbar>
                <AuthenticatedTemplate>
                    <Pages />
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                    <NoAuthPages />
                </UnauthenticatedTemplate>
            </LocalizationProvider>
        </HotKeys>
    );
};

export default WebApp;
