import { Box, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "../../configureStore";
import globalStyles from "../../globalStyles";
import { StringTotal } from "../../interfaces/report";

const TopProcessors = () => {
    const allRequests = useSelector<RootState, number>(({ stats: { stats } }) => stats.highlights.Borrowers)
    const data = useSelector<RootState, StringTotal[]>(({ stats: { stats } }) => stats.processors)

    return (
        <Box sx={{ width: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            {
                (data.length && (allRequests > 0)) ? (
                    <TableContainer component={Box}>
                        <Box sx={[globalStyles.flexCenterCenter]}>
                            <Typography variant="h6">
                                Top {data.length} Loan Processors
                            </Typography>
                        </Box>
                        <Table>
                            <TableBody>
                                {data.map(({ name, total }, i) => (
                                    <TableRow
                                    key={`processor-${name}-${i}`}
                                        sx={{ '&:last-child td, &:last-child th': { borderBottom: 0 } }}
                                    >
                                        <TableCell sx={[{ backgroundColor: '#EAEEF4', border: '1px white solid' }]} align="center">{name}</TableCell>
                                        <TableCell sx={[{ backgroundColor: '#EAEEF4', border: '1px white solid' }]} align="center">{total}</TableCell>
                                        <TableCell sx={[{ backgroundColor: '#EAEEF4', border: '1px white solid' }]} align="center">{((total / allRequests) * 100).toFixed(1)}%</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                ) : (
                    <Typography>No orders placed</Typography>
                )
            }
        </Box>
    );
};

export default TopProcessors;
