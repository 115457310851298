import { BarChart } from "@mui/x-charts";
import SmallBar from "../../atoms/SmallBar";
import { useSelector } from "react-redux";
import { RootState } from "../../../configureStore";
import { MonthWise, MonthWiseOpt } from "../../../interfaces/report";
import { useMemo } from "react";
import { ComparisonConfig } from "../../../interfaces";
import { graphColors } from "../../../utils/colors";

interface ComparisonParams {
    index: number;
}

const Comparison = ({
    index,
}: ComparisonParams) => {
    const config = useSelector<RootState, ComparisonConfig | undefined>(({ tabs: { tabs } }) => tabs[index].config as ComparisonConfig);
    const stats = useSelector<RootState, MonthWise[]>(({ stats: { stats: { monthly } } }) => monthly);

    const dataset = useMemo<any[]>(() => stats.map((month) => {
        const data: MonthWiseOpt = { month: month.month };

        if (config?.details["Loan Professionals"]) data["Loan Professionals"] = month["Loan Professionals"];
        if (config?.details.Loans) data.Loans = month.Loans;
        if (config?.details.Borrowers) data.Borrowers = month.Borrowers;
        if (config?.details.Transcripts) data.Transcripts = month.Transcripts;
        return data;
    }), [stats, config])

    const series = useMemo(() => {
        const sers = [];
        if (config?.details["Loan Professionals"]) sers.push({ dataKey: 'Loan Professionals', label: 'Loan Professionals', color: graphColors[0] });
        if (config?.details.Loans) sers.push({ dataKey: 'Loans', label: 'Loans', color: graphColors[1] });
        if (config?.details.Borrowers) sers.push({ dataKey: 'Borrowers', label: 'Borrowers', color: graphColors[2] });
        if (config?.details.Transcripts) sers.push({ dataKey: 'Transcripts', label: 'Transcripts', color: graphColors[3] });
        return sers;
    }, [config]);

    return (
        <div style={{ height: '100%', flexDirection: 'column', display: 'flex', justifyContent: 'space-between' }}>
            <SmallBar type="Comparison" />
            <BarChart
                xAxis={[{ scaleType: 'band', dataKey: 'month' }]}
                series={series}
                dataset={dataset}
                barLabel={(item, ctx) => {
                    if (!item.value) return null;
                    if (ctx.bar.height < 8) return null;
                    return item.value.toString();
                }}
                slotProps={{
                    legend: {
                        position: {
                            vertical: 'bottom',
                            horizontal: 'middle',
                        },
                        labelStyle: {
                            fontSize: 12,
                        },
                        itemMarkWidth: 12,
                        itemMarkHeight: 4,
                    },
                    barLabel: {
                        fill: '#FFFFFF',
                        stroke: "#FFFFFF",
                    },
                }}
            />
        </div>
    );
};

export default Comparison;
