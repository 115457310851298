import { Box, Paper } from "@mui/material";
import globalStyles from "../../globalStyles";
import { ReportTabType } from "../../interfaces";
import Title from "./Slides/Title";
import { useRef } from "react";
import Highlights from "./Slides/Highlights";
import Main from "./Options/Main";
import Comparison from "./Slides/Comparison";
import Completion from "./Slides/Completion";
import LoadingOverlay from "../atoms/LoadingOverlay";
import { useSelector } from "react-redux";
import { RootState } from "../../configureStore";
import Action from "./Slides/Action";
import Orders from "./Slides/Orders";

export interface ReportTabParams {
    value: number;
    index: number;
    type: ReportTabType;
    visible: boolean;
}

const ReportTab = ({
    value,
    index,
    type,
    visible,
}: ReportTabParams) => {
    const ref = useRef<any>(null);
    const loading = useSelector<RootState, boolean>(({ status }) => status.loading['stats/GET_STATS']);

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
        >
            {value === index && (
                <Box
                    sx={globalStyles.mt2}
                >
                    {visible && <Main index={index} type={type} />}
                    <Box sx={globalStyles.flexCenterCenter}>
                        {loading && <LoadingOverlay />}
                        <Paper
                            ref={ref}
                            id={`slide-${index}`}
                            variant="elevation"
                            className={loading ? 'none' : undefined}
                            sx={[globalStyles.slide, { borderRadius: 0 }]}
                            elevation={10}
                        >
                            {
                                type === 'Title' ? <Title current={ref.current} />
                                    : type === 'Highlights' ? <Highlights index={index} />
                                        : type === 'Comparison' ? <Comparison index={index} />
                                            : type === 'Completion' ? <Completion />
                                                : type === 'Action' ? <Action index={index} />
                                                    : type === 'Orders' ? <Orders />
                                                        : <div style={{ height: '80%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>Please choose a slide type</div>
                            }
                            <img width="10%" style={{ position: 'absolute', top: 8, right: 8 }} src="/Kingfisher.png" alt="Halcyon" />
                        </Paper>
                    </Box>
                </Box>
            )}
        </div>
    );
};

export default ReportTab;
