import { AppDispatch } from "../../configureStore";

export type StatusState = {
    loading: any;
    success: any;
    failure: any;
    successful?: string;
    error?: string;
};

const initialState: StatusState = {
    loading: {},
    success: {},
    failure: {},
};

export const ERROR_MESSAGE = 'status/ERROR_MESSAGE';
export const CLEAR_ERROR = 'status/CLEAR_ERROR';

export const SUCCESSFUL_MESSAGE = 'status/SUCCESSFUL_MESSAGE';
export const CLEAR_SUCCESSFUL = 'status/CLEAR_SUCCESSFUL';

const reducer = (state = initialState, { type: actionType, message }: { type: string; message?: string; }) => {
    switch (actionType) {
        case SUCCESSFUL_MESSAGE:
            return {
                ...state,
                successful: message,
            };
        case CLEAR_SUCCESSFUL:
            return {
                ...state,
                successful: undefined,
            };
        case ERROR_MESSAGE:
            console.log({ error: message, })
            return {
                ...state,
                error: message,
            };
        case CLEAR_ERROR:
            return {
                ...state,
                error: undefined,
            };
        default:
            const matches = /(\w+)\/(\w+)_(REQUEST|SUCCESS|FAILURE)/.exec(actionType);

            if (!matches) return state;

            const [, reducerName, actionName, requestState] = matches;
            const namespace = `${reducerName}/${actionName}`;

            return {
                ...state,
                loading: {
                    ...state.loading,
                    [namespace]: requestState === 'REQUEST',
                },
                success: {
                    ...state.success,
                    [namespace]: requestState === 'SUCCESS',
                },
                failure: {
                    ...state.failure,
                    [namespace]: requestState === 'FAILURE',
                },
            };
    }
};

export const clearError = () => (dispatch: AppDispatch) => {
    dispatch({
        type: CLEAR_ERROR,
    });
};

export const clearSuccessful = () => (dispatch: AppDispatch) => {
    dispatch({
        type: CLEAR_SUCCESSFUL,
    });
};

export default reducer;
