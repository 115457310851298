import { TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../configureStore";
import { SET_USE_NAME } from "../../../redux/reducer/institution";

const Title = () => {
    const useName = useSelector<RootState, string | undefined>(({ institution: { activeInstitution, institutions, useName: un } }) => (un || institutions?.[activeInstitution || '']?.Name));
    const dispatch = useDispatch<AppDispatch>();

    return (
        <TextField
            defaultValue={useName}
            placeholder="Custom Name"
            size="small"
            onChange={(e) => {
                dispatch({
                    type: SET_USE_NAME,
                    name: e.target.value,
                });
            }}
        />
    );
};

export default Title;
