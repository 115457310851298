import { useRef } from "react";
import { ITab, ReportTabType } from "../../interfaces";
import { AllStats } from "../../interfaces/report";
import { Box, Paper } from "@mui/material";
import globalStyles from "../../globalStyles";
import Title from "./StaticSlides/Title";
import Highlights from "./StaticSlides/Highlights";
import Comparison from "./StaticSlides/Comparison";
import Completion from "./StaticSlides/Completion";
import Action from "./StaticSlides/Action";
import Orders from "./StaticSlides/Orders";

interface ReportTabClientParams {
    index: number;
    stats: AllStats;
    type: ReportTabType;
    startDate: string;
    endDate: string;
    active?: string;
    tabs: ITab[];
    value: number;
}

const ReportTabClient = ({
    type,
    stats,
    index,
    startDate,
    endDate,
    active = '',
    tabs,
    value,
}: ReportTabClientParams) => {
    const ref = useRef<any>(null);

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
        >
            <Box sx={globalStyles.mt2}>
                <Paper
                    ref={ref}
                    id={`print-slide-${index}`}
                    variant="elevation"
                    sx={[globalStyles.printSlide, { borderRadius: 0 }]}
                    elevation={10}
                >
                    {
                        type === 'Title' ? <Title current={ref.current} endDate={endDate} startDate={startDate} active={active} />
                            : type === 'Highlights' ? <Highlights index={index} tabs={tabs} stats={stats} />
                                : type === 'Comparison' ? <Comparison index={index} tabs={tabs} stats={stats.monthly} />
                                    : type === 'Completion' ? <Completion stats={stats.completion} />
                                        : type === 'Action' ? <Action index={index} actions={stats.actionReasons} />
                                            : type === 'Orders' ? <Orders stats={stats} />
                                                : <div style={{ height: '80%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>Please choose a slide type</div>
                    }
                    <img width="10%" style={{ position: 'absolute', top: 8, right: 8 }} src="/Kingfisher.png" alt="Halcyon" />
                </Paper>
            </Box>
        </div>
    );
};

export default ReportTabClient;
